import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import CreateDivisionForm from 'manage-tritag/components/pages/divisions/create-form'

const CreateDivisionPage = () => {
  return (
    <Layout label1="Divisions" label2="Create" url="/divisions">
      <CreateDivisionForm />
    </Layout>
  )
}

export default withAuthenticationRequired(CreateDivisionPage)
