import { api } from '../index'

export interface Division {
  _id: string
  ttr_id: number
  name: string
  ageFrom: number
  ageTo: number
  gender: string
  gameDuration: number
  status: boolean
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type DivisionsResponse = Division[]

export const divisionsApi = api.injectEndpoints({
  endpoints: build => ({
    getDivisions: build.query<DivisionsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'divisions/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['divisions'],
    }),
    getDivision: build.query<Division, string>({
      query: id => `divisions/${id}`,
      providesTags: ['division'],
    }),
    addDivision: build.mutation({
      query: body => ({
        url: 'divisions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['divisions'],
    }),
    updateDivision: build.mutation({
      query: body => ({
        url: `divisions/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['divisions', 'division'],
    }),
    deleteDivision: build.mutation({
      query: id => ({
        url: `divisions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['division', 'divisions'],
    }),
  }),
})

export const {
  useGetDivisionsQuery,
  useGetDivisionQuery,
  useLazyGetDivisionsQuery,
  useAddDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,
} = divisionsApi
